/* Contenedor principal del blog usando Grid */
.blog {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Dos columnas con ancho mínimo ajustable */
    gap: 1rem; /* Espacio entre los contenedores */
    width: 100%;
    max-width: 1600px; /* Ancho máximo del contenedor principal */
    margin: 0 auto; /* Centra el contenedor principal horizontalmente */
    padding: 1rem;
    box-sizing: border-box;
  }
  
  /* Estilo para cada tarjeta */
  .blog__container {
    background: linear-gradient(to right, #004294, #254387);
    border: 1px solid transparent;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    box-sizing: border-box;
    height: auto; /* Ajusta la altura automáticamente */
    margin: 0; /* Sin márgenes adicionales */
  }
  
  /* Estilo para las imágenes */
  .blogg__img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
.blog_titulo1 {

    padding-left: -11rem; /* Ajusta el padding para el tamaño deseado */
   

}

.btn-read {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-grey-200);
  background: var(--color-secondary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.btn-read:hover {
    color: var(--color-gray-300);
}

.blog__seccion h2 {
    color: #ffffff;
    font-size: 2rem;
}

/* FAQs */

.faqs__wrapper {
    margin-top: 4rem;
    display: flex; /* Cambia de grid a flexbox para la disposición vertical */
    flex-direction: column; /* Organiza los elementos en una sola columna */
    gap: 1.5rem; /* Espacio entre los elementos */
    align-items: flex-start; /* Alinea los elementos al inicio del contenedor */
}

.faq {
    background: linear-gradient(to right, #E7E8E8, #E7E8E8);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    width: 100%; /* Asegura que el elemento FAQ ocupe todo el ancho disponible */
}

/* Cambia el color del texto dentro del contenedor FAQ a negro */
.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: #BF373B; /* Cambia el color del ícono a negro */
    font-size: 1.5rem;
}

/* Cambia el color del texto del párrafo dentro del FAQ a negro */
.faq p {
    margin-top: 1.5rem;
    color: #000000; /* Cambia el color del texto del párrafo a negro */
    text-align: left;
}


/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
    .blog {
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
    }
}

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
   .blog {
       grid-template-columns: 1fr;
        gap: 1rem;
    }
    .card.blog {
        width: 90%;
        margin-inline: auto;
    
    }

    @media screen and (max-width: 768px) {
        .blog {
          grid-template-columns: 1fr; /* Cambia a una columna en pantallas pequeñas */
          gap: 1rem; /* Ajusta el espacio entre los contenedores en pantallas pequeñas */
        }
      }
}