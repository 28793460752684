.contact__wrapper a {
    color: #ffffff;
    background: var(--color-gray-300);
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
    padding: 1rem;
    border-radius: 1.5rem;
}

.contact__wrapper a:hover {
    background: transparent;
    border-color: var(--color-secondary);
}

.contact__container {
    display: grid;
    place-items: center;
}

.contact__wrapper {
    width: fit-content;
    display: flex;
    gap: 2rem;
}

.mapa1 {
    border: 0;
    width: 600px;
    height: 450px;
}

.contact__ubicacion {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 5rem 4rem;
}

.mapa1 {
    background: var(--color-gray-300);
    border: 2px solid transparent;
    text-align: center;
    /*padding: 3rem 2rem;*/
    border-radius: 0 3rem 0 3rem;
    transition: var(--transition);
}

.contact__text {
    text-align: center;
}

.contact__text h5 {
    color: var(--color-gray-200);
}

hr {
    margin: 0 4rem;
}

p {
    color: var(--color-gray-200);
    font-weight: bold;   
}

.title_contact{
    color: var(--color-gray-200);
}

.contact__text p {
    color: var(--color-gray-200);
    /*margin-left: 2rem;
}

.btn {
    color: var(--color-gray-200) !important;
}

.btn:hover {
    background: var(--color-secondary) !important;
}

.main_contact {
    width: 100vw;
    display: grid;
    place-items: center;
    margin-top: 1rem;
}

@media screen and (max-width: 1024px){
    /*---MAIN HEADER---*/
    .main_contact {
       height: fit-content;
       padding: 6rem 0;
    }
   
    .contact__ubicacion {
       gap: 0;
    }

}


/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
    .main_contact {
        margin-top: 0;
        padding: 6rem 0 0;
    }

    .contact__ubicacion {
        grid-template-columns: 1fr;
        text-align: center;
        padding: 0;
        /*width: 100%;*/
    }

    .mapa1 {
        width: 80%;
    }
}


