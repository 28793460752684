nav {
    height: 5rem; /* 16px * 5 */
    width: 100vw; /* Ancho completo */
    max-width: 100%; /* Asegura que no se salga del viewport */
    background: var(--color-gray-900);
    display: grid;
    place-items: center;
    position: fixed; /* Nav se queda fijo */
    top: 0;
    left: 0;
    z-index: 99;
    font-size: 15px;  
    padding: 0 1rem; /* Añade espacio en los lados en pantallas pequeñas */
}



/* only show on medium and small screens*/

.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 6rem;
    display: block;
    margin-left: -3rem;
    margin-right: -1rem;
}

.logo:hover {
    background: transparent;
}

.nav__links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
    font-family: 'Helvetica', sans-serif;
}

.nav__links a {
    transition: var(--transition);
}

.nav__links a:hover {
    color: var(--color-secondary);
}

.active-nav {
    position: relative;
}

.active-nav::after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    margin-top: 0.9rem;
}

/*MEDIA QUERIES (medium and small screens) */

@media  screen and (max-width: 1024px) {
        .nav__toggle-btn {
            display: inline-block;
            background: transparent;
            font-size: 1.8rem;
            cursor: pointer;
        }

        .nav__toggle-btn svg {
            color: var(--color-gray-200);
        }

        .nav__links {
            position: absolute;
            top: 100%;
            right: 0;
            flex-direction: column;
            gap: 0;
        }

        .active-nav, .active-nav::after {
            display: none;
        }

        .nav__links li {
            height: 4rem;
            width: 100%;
            box-shadow: -2rem 2rem 5rem rgba(0,0,0, 0.4);
        }

        .nav__links li a {
            color: var(--color-gray-200);
            background-color: #ffffff;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 1rem 5rem 1rem 3rem;
        }

        .show__nav {
            display: flex;
        }

        .hide__nav {
            display: none;
        }
    
}

@media (max-width: 1030px)  {
    .logo {
        margin-left: -1rem;
    }
       
    }