.wakeRooms__container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.title_container {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.title_container p {
    font-size: 1.7rem;
}


.gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    margin: 0 4rem;
    
  }
  
  .gallery img {
    height: auto;
    margin-bottom: 10px;
    margin-bottom: 10px;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    border-radius: 0 3rem 0 3rem;
  }

  .gallery img:hover {
    transform: scale(1.2);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

/*MEDIA QUERIES (medium screens)*/
@media screen and (max-width: 1024px) {
    .gallery__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .gallery__container img:hover {
        width: 20rem;
    }    
}

/*MEDIA QUERIES (small screens)*/
@media screen and (max-width: 600px) {
    .gallery__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .gallery__container img:hover {
        width: 27rem;
    }
}

