.container_funeralservices {
    padding: 0 3rem;
}
.menu_icons {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    text-align: -webkit-center;
     box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
    border-radius: 0 4rem 0 4rem;
}

.columna img {
    width: 6rem;
}

.title_icons {
    color: var(--color-gray-200) !important;
    font-size: 1rem;
    font-weight: bold;
}

.title_menu_services {
    text-align: center;
    font-weight: bold;
    /*text-decoration: underline;*/
    padding: 0 0 3rem 0;
    font-size: 2rem;
}

header {
    color: #ffffff;
}

.paragraph_staff {
    color: var(--color-gray-200);
    font-size: 1rem;
    font-weight: bold;
}

.container_funeralservices_bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.funeralservices_right {
    padding: 0 2rem;
    text-align: center;
}

.funeralservices_left img{
    border-radius: 0 4rem 0 4rem;
}

.prenecesidad {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
    margin: 3rem 0;
}

.prenecesidad h2 {
    font-size: 25px;
    color: var(--color-gray-200);
}

.descripcion_pre {
    font-size: 18px !important;
    color: var(--color-gray-200);
}

.descripcion_ni {
    font-size: 18px !important;
    color: var(--color-gray-200);
}

.ni {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}

.ni h2 {
    font-size: 25px;
    color: var(--color-gray-200);
}

.btn-services {
    background: var(--color-gray-200);
    color: #ffffff;
    padding: 5px 50px;
    border-radius: 8px;
}

.btn-services:hover {
    background: transparent;
    border: solid 2px var(--color-gray-200);
}

.title_servicesexe {
    color: var(--color-gray-200);
    /*text-decoration: underline;*/
    font-weight: bold;
}


/*MEDIA QUERIES (medium screens)*/
@media screen and (max-width: 1024px) {
    .menu_icons {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

/*MEDIA QUERIES (small screens)*/
@media screen and (max-width: 600px) {
    .menu_icons {
        grid-template-columns: 1fr 1fr;
      
    }

    .container_funeralservices_bottom {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .columna img {
        width: 3rem;
        height: 2rem;
    }
}