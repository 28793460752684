*, *::before, *::after  {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --primary-hue: 280;
    --gray-hue: 260;
    --color-primary: hsl(var(--primary-hue), 100%, 50%);
    --color-primary-variant: hsl(var(--primary-hue), 100%, 65%);
    --color-secondary: #E6E7E7;/*FOOTER*/
    --color-gray-100: #000000;
    --color-gray-200: #000000;
    --color-gray-300: #004294;
    --color-gray-900: #004294; /*MAIN*/
    --color-gray-9001:#ffffff;
    --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
    --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
    --color-gray-600: hsl(var(--gray-hue), 48%, 15%);

    --container-width-lg: 80%;
    --container-width-md: 90%;

    --transition: all 500ms ease;


}

/*GENERAL STYLES*/
body {
    /*font-family: 'Montserrat', sans-serif;*/
    font-family: 'Helvetica', sans-serif; 
    color: var(--color-gray-200);
    line-height: 1.7;
    overflow-x: hidden;
    /*background:  linear-gradient(to right, #B3B6B7 , #D0D3D4, #F2F3F4);*/
    background:  #ffffff url('./Imagenes/bg_texture.png') ;
    /*url('./Imagenes/bg_texture.png') ;*/
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
    text-align: justify;
    
}

h1,h5, p {
    line-height: 1.2;
    color: #ffffff;
    text-align: center;
}

.titulo_car{
    line-height: 1.2;
    color: #ffffff;
    text-align: left;
    
}

.preg{
    line-height: 1.2;
    color: #000000;
    text-align: left;
    
}


h1 {
    font-size: 3rem;
    
}

h2 {
    font-size: 2rem;
    /*color: #ffffff;*/
    
    
}

a {
    color: var(--color-gray-9001);
    text-decoration: none;
    
}

p {
    color: white;
    font-weight: bold;
    text-align: justify;
    font-size: 15px;
}

a:hover {
    color: var(---color-gray-200) !important; 
    text-decoration: none;
}

img {
    display: block;
    object-fit: cover; /*Ajustarse a su contenedor*/
    width: 100%;
    height: auto;

}


.btn {
    color: var(--color-gray-100);
    width: fit-content;
   /* margin: 10 auto;*/
    padding: 0.9rem 2rem;
    border-radius: 1.5rem;
    transition: var(--transition);
    font-weight: bold;
    
}

.btn:hover {
    background: var(--color-secondary);
    color: #ff00f7 !important; 
    font-weight: bold;
    
}

.btn.lg{
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.programs__program {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Hace que todas las tarjetas tengan la misma altura */
}

.programs__program h4 {
    margin-bottom: auto; /* Empuja el título hacia arriba */
}

.btn.sm {
   /* padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
    display: inline;
    margin-top: 2rem;*/
    align-self: flex-start; /* Asegura que el botón esté alineado */
    margin-top: auto; /* Empuja el botón hacia la parte inferior */
}

.btn.sm:hover{
    background: var(--color-secondary);
    color: #6A1867 !important;
}





section {
    margin-top: 5rem;
}

.section__head {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    
}

.section__head span {
    background: linear-gradient(to right, #144394, #E6E7E7);
    padding: 0.4rem;
    border-radius: 0.8rem;
    color: var(--color-gray-200);
    font-size: 1.5rem;
    /*margin-left: 5rem;*/
}

.card {
    background: linear-gradient(to right, #004294, #254387);
    border: 1px solid transparent;
    text-align: center;
    padding: 2rem ;
    border-radius: 0 2rem 0 2rem;
    transition: var(--transition);
    border-right: none;
    text-align: justify;
    
    min-width: 220px;
    
}

.card-small {
    background: linear-gradient(to right, #004294, #254387);
    border: 1px solid transparent;
    text-align: center;
    padding: 0.5rem; /* Ajusta el padding para la tarjeta pequeña */
    border-radius: 0 1rem 0 1rem; /* Ajusta el border-radius */
    transition: var(--transition);
    border-right: none;
    text-align: justify;
    font-size: 0.75rem; /* Ajusta el tamaño del texto */
}

.card:hover {
    
    border-color: var(--color-secondary);
    cursor: default;
}

.card span {
    width: 3rem;
    height: 3rem;
    background: var(--color-gray-200);
    color: #ffffff;
    font-size: 1.5rem;
    padding: 0.8rem;
    display: grid;
    place-items: center;
    margin-bottom: 1.5rem;
    margin-inline: auto;
    border-radius: 1rem;
    transition: var(--transition);
}


.card:hover span {
    background: linear-gradient(to right,  #004294, #254387);
    color: #ffffff;
}

.card small {
    margin-top: 1rem;
    display: block;
    font-weight: 300;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: justify;
}






.header {
    margin-top: 5rem;
    height: 20rem;
    overflow: hidden;
    border-bottom: 2px solid var(--color-gray-900);
}

.header__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: black;
}

.header__container-bg {
    position: absolute;
    width: 100%;
}

.header__container-bg img {
    opacity: 0.4;
}

.header__content {
    position: relative;
    width: 44%;
    margin: 0 auto;
    text-align: center;
}

.header__content p {
    color: rgba(255, 255, 255, 0.7);
}






/*--------MEDIA QUERIES (medium screens) ------------*/

@media screen and (max-width: 1024px){
    .container {
        width: var(--container-width-md);
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .header__content {
        width: 76%;
    }
}

/*--------MEDIA QUERIES (medium screens) ------------*/

@media screen and (max-width: 800px){
    .container {
        width: var(--container-width-md);
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .header__content {
        width: 76%;
    }
}

/*-----------------MEDIA QUERIES (small screens)-----------*/

@media screen and (max-width: 600px) {
    h1 {
        font-size: 2.2rem;
        line-height: 1.3;
    }

    section {
        margin-top: 7rem;
    }

    .header {
        height: fit-content;
    }

    .header__content {
        width: var(--container-width-md);
        padding: 1rem 0;
    }

    .header__content p {
        font-size: 0.85rem;
    }

}
