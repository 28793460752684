.about__section-image {
    border-radius: 50%;
    overflow: hidden;
    transform: skew(10deg); /* Distorsionar ángulos */
    transition: var(--transition);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    margin: 0.5rem 0; /* Reducir el margen superior e inferior */
}

.about__section-image:nth-child(2) {
    transform: skew(-10deg);
}

.about__section-image:hover {
    transform: skew(0deg);
}

.about__section-content h1 {
    margin-bottom: 0.8rem; /* Reducir el margen inferior del encabezado */
    color: var(--color-gray-300);
    font-weight: bold;
}

.about__section-content p {
    margin-bottom: 0.3rem; /* Reducir el margen inferior del párrafo */
    color: var(--color-gray-200);
    font-weight: bold;
    text-align: justify;
}

/* Reducir el margen superior e inferior de todos los contenedores */
.about__story-container, 
.about__mission-container,
.about__vision-container,
.about__values-container {
    display: grid;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.3); /* Tamaño de la sombra */
    border-radius: 0 1.5rem 0 1.5rem; /* Radio del borde */
    margin: 0.25rem 0; /* Ajusta el margen superior e inferior para reducir el espacio */
    padding: 0.25rem; /* Padding interno */
}


/* Estilo por defecto para pantallas grandes */
.about__story-container {
    grid-template-columns: 35% 60%; /* Mantener el ancho de las columnas */
    gap: 1%; /* Reducir el espacio entre las columnas */
}

.about__mission-container {
    grid-template-columns: 35% 60%; /* Mantener el ancho de las columnas */
    gap: 1%; /* Reducir el espacio entre las columnas */
}

.about__vision-container {
    grid-template-columns: 60% 35%; /* Mantener el ancho de las columnas */
    gap: 1%; /* Reducir el espacio entre las columnas */
}

.about__values-container {
    grid-template-columns: 60% 35%; /* Mantener el ancho de las columnas */
    gap: 1%; /* Reducir el espacio entre las columnas */
}

/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px){
    
    .about__section-image {
        width: 50%; /* Reducir el tamaño de la imagen en pantallas medianas */
        margin-inline: auto;
    }

    .about__section-content h1 {
        margin-bottom: 0.6rem; /* Ajustar el margen inferior del encabezado */
    }

    .about__section-content p {
        margin-bottom: 0.3rem; /* Reducir el margen inferior del párrafo */
    }

    .about__story-container, 
    .about__mission-container,
    .about__vision-container,
    .about__values-container {
        grid-template-columns: 1fr; /* Una sola columna en pantallas medianas */
        gap: 0.5rem; /* Reducir el espacio entre los elementos */
        margin: 0.25rem 15rem; /* Reducir el margen superior e inferior */
    } 
}

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px){

    .about__section-image {
        width: 70%; /* Reducir aún más el tamaño de la imagen en pantallas pequeñas */
        margin-inline: auto;
    }

    .about__section-content h1 {
        font-size: 1.2rem; /* Ajustar el tamaño de fuente del encabezado */
        margin-bottom: 0.4rem; /* Reducir el margen inferior del encabezado */
    }

    .about__section-content p {
        font-size: 0.875rem; /* Ajustar el tamaño de fuente del párrafo */
        margin-bottom: 0.2rem; /* Reducir el margen inferior del párrafo */
    }

    .about__story-container, 
    .about__mission-container,
    .about__vision-container,
    .about__values-container {
        gap: 0.25rem; /* Reducir el espacio entre los elementos en pantallas pequeñas */
        margin: 0.2rem 0; /* Reducir el margen superior e inferior */
    } 
}

@media (max-width: 1280px) and (max-height: 800px) {
    .about__section-image img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
