#h4num {
    background-color: #ffffff;
    color: var(--color-gray-200); 
    border-radius: 30px;
    text-align: center;
    size: 16rem;
    padding-bottom: 10px;
    padding-top: 10px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}

#btnservicios {
    color: #ffffff;
    margin-top: 10px;
    border-top: 25px;
    top: 20px;
    position: relative;
}

/*BOTONES DE CELULAR*/
h3 a {
    color: #ffffff;
    background: linear-gradient(to right, #144394, #4b5fa6);
    border-radius: 8px;
    padding: 1px 1px;
}

.main__header {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 1rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main__header-left {
    margin-top: -3rem;
}

.main__header-left img{
    margin-top: 5rem;
}

.main__header-left h4 {
    margin-bottom: 1rem;
    color: #ffffff;
    font-weight: 400;
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.5rem;
    color: var(--color-gray-200);
}

.main__header-left h2 {
    color: var(--color-gray-200);
}

.main__header-right img {
    margin-top: 6rem;
}

.main__header-left img {
    margin-top: 10rem;
}

/*Services*/

.programs {
    margin-top: 8rem;
}

.programs__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.programs__program a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: var(--color-gray-200) !important;
}

.programs__program:hover a {
    background: var(--color-secondary);
    color: var(--color-gray-300);
}


/*ABOUT*/

.about__container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}

.about__image {
  /*  filter: saturate(0.25);*/
    transition: var(--transition);
    width: 35rem;
    height: 20rem;
}

.about__imagen {
    /*  filter: saturate(0.25);*/
      transition: var(--transition);
      width: 35rem;
      height: 20rem;
  }


.about__right > p {
    margin: 1.5rem 0 5rem;
    color: var(--color-gray-200);
    text-align: justify;
    /*margin-left: 5rem;*/
}

.about__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}

.card.about__value {
    padding-top: 3.5rem;
    text-align: left;
    position: relative;
}

.card.about__value span {
    position: absolute;
    top: -1.5rem;
}





/* FAQs */

.faqs__wrapper {
    margin-top: 4rem;
    display: flex; /* Cambia de grid a flexbox para la disposición vertical */
    flex-direction: column; /* Organiza los elementos en una sola columna */
    gap: 1.5rem; /* Espacio entre los elementos */
    align-items: flex-start; /* Alinea los elementos al inicio del contenedor */
}

.faq {
    background: linear-gradient(to right, #004294, #4b5fa6);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    width: 100%; /* Asegura que el elemento FAQ ocupe todo el ancho disponible */
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: #ffffff;
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;
    color: #ffffff;
}




/*Testimonials*/

.testimonials {
    width: 50rem;
    margin-inline: auto;
}

.testimonials__head {
    justify-content: center;
}

.card.testimonial {
    position: relative;
    text-align: left;
    margin-top: 5rem;
}

.testimonial__quote {
    margin: 1rem 0 2rem;
    color: #ffffff;
}

h5 {
    color: #ffffff !important;
}

.card small.testimonial__title {
    margin-top: 0.3rem;
}

.testimonials__btn-container {
    width: fit-content; /*Ajusta al tamaño disponible*/
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonials__btn {
    background: transparent;
    cursor: pointer;
}

.testimonials__btn svg {
    fill: var(--color-gray-200);
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}






/*Footer*/

#footer__socials a {
    color: var(--color-gray-200);
    font-weight: bold;
}

.linka {
    color: var(--color-gray-200) !important;
    font-weight: bold;
    
}


.linka:hover {
    color: #004294 !important;
    font-weight: bold;
}

footer p{
    color: var(--color-gray-200) !important;
    font-weight: bold;
}

footer p:hover {
    color:  #004294 !important;
}

footer h4{
    color: var(--color-gray-300);
    font-weight: bold;
}

footer {
    background: var(--color-secondary);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
    font-weight: bold;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.7rem;
}

.footer__socials a {
    padding: 0.7rem;
    border: 1px solid transparent;
    transition: var(--transition);
    font-weight: bold;
}

.footer__socials a:hover {
    padding: 0.7rem;
    border: 1px solid transparent;
    transition: var(--transition);
    color:  #004294 !important;
}

.footer__copyright {
    color: var(--color-gray-200);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-gray-200);
    margin-top: 5rem;
}

.imgiess {
    width: 8rem;
}

.footer_logo {
    width: 300px; /* Ajusta el tamaño de la imagen según sea necesario */
    display: flex;
    align-items: center; /* Centra verticalmente si hay contenido adicional */
    justify-content: flex-start; /* Alinea la imagen a la izquierda */
    padding: 0; /* Elimina cualquier relleno en el contenedor */
    margin: 0; /* Elimina márgenes adicionales */
}


/*--------MEDIA QUERIES (medium screens) ------------*/

@media screen and (max-width: 1024px){
 /*---MAIN HEADER---*/
 .main__header {
    height: fit-content;
    padding: 6rem 0;
 }

 .main__header-container {
    gap: 0;
 }

 /*PROGRAMS*/

 .programs__wrapper {
    grid-template-columns: 1fr 1fr;
 }

/*ABOUT*/

.about__container {
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 4rem;
}

.about__wrapper {
    gap: 4rem 3rem;
}

.about__image {
    /*display: none;*/
    display: none;
}

/*FAQ*/

.faqs__wrapper {
    grid-template-columns: 1fr;
}

/*Testimonials*/

.testimonials {
    width: 75%;
}

/*FOOTER*/
.footer__container {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
}
.footer_logo {
    width: 300px; /* Ajusta el tamaño de la imagen según sea necesario */
    display: block; /* Asegura que se comporte como un bloque */
    margin: 0; /* Elimina cualquier margen que pueda empujarlo */
    padding: 0; /* Elimina cualquier relleno adicional */
    float: left; /* Alinea la imagen a la izquierda */
}

}

/* -------- MEDIAS QUERIES (small screens) */

@media screen and (max-width: 600px) {

    .main__header-right img {
        margin-top: 2rem;
    }

    
    .programs {
        margin-top: 2rem;
    }

    /*MAIN HEADER*/
    .main__header {
        margin-top: 0;
        padding: 6rem 0 0;
        /*height: 100vh;*/
    }

    /*.main__header-image {
      display: none;
    }*/

    .main__header-container {
        grid-template-columns: 1fr;
    }

    /*PROGRAMS*/
    .programs__wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }

    .programs__program {
        width: 90%;
        margin: 0 auto;
    }

    /* ABOUT */

    .about__wrapper {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .card.about__value {
        width: 90%;
        margin-inline: auto;
    }

    .about__imagen {
        width: auto;
        margin-bottom: -80px;
    }

    /*FAQs*/

    .faqs__wrapper {
        gap: 1rem;
        margin-top: 3rem;
    }

    /*TESTIMONIALS*/

    .testimonials {
        width: 100%;
    }

    /*footer*/

    footer {
        margin-top: 7rem;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer__container article {
        align-items: center;
    }

    .footer__container article p {
        text-align: center;
    }
    .footer_logo {
        width: 300px;
        display: block;
        margin: 0; /* Asegura que no haya margen por defecto */
        padding: 0;
        float: left;
        margin-left: -100px; /* Mueve la imagen más a la izquierda */
    }
}


@media (max-width: 1280px) and (max-height: 800px) {
    .about__container {
      display: flex;
      flex-direction: column;
    }
    
    .about__left,
    .about__right {
      width: 100%;
    }

    .about__left {
        display: flex;
        flex-direction: row; /* Alinea las imágenes horizontalmente */
        gap: 20px;
      }

      .about__imagen img,
        .about__image img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Asegura que la imagen se ajuste sin distorsión, recortando si es necesario */
        }

  }
  

